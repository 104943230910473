.main{
    display: flex;
    justify-content: space-between;
    height: 100vh;
    background-color: white;
    width: 100%;
}

.main img{
   
}
.left{
    width:60%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #F9F9F9;
}
.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:40%;
}

.carousal{
    width:700px;
    height:660px;
}

/*for Request Access heading*/
.heading{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: -15px;
    margin-top: -40px;
}

.major{
    display:flex;
    width: 60%;
    max-width: 400px;
   border: 0.2px solid #c0b7fa;
   border-radius: 20px;
    flex-direction: column;   
    justify-content:center;
    box-sizing: border-box;
   
}

.minor{
    height: 10%;
    display:flex;
    align-items: center;
    justify-content: center;
    border-top: solid;
    border-color: white;
    border-width: 1px;
}

.right button{
   border-radius: 7px;
   font-weight: 500;
}

.right button:hover{
   
}

.formic{
    width:100%;
    display: flex;
    padding:20px;  
    gap:20px;  
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
   
}
.fieldContainer{
    width:100%;
    display:flex;
    gap:10px;
    flex-direction: column;
    align-items: flex-start;

}

.submit{
    color: white;
    background-color: rgb(48, 48, 48);
    border-style: solid;
    border-color: rgb(48, 48, 48);
    border-radius: 50px;
    padding: 5px;
    padding-right: 30px;
    padding-left: 30px;
    
}

.username,.password{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:rgb(0, 0, 0);
    background-color: white;
    border-style: solid;
    border-color: white;
    border-radius: 50px;
    padding: 5px;
    padding-right: 30px;
    padding-left: 10px;
    

}

.username img{
    padding: 0%;
    width: 15px;
}

.password img{
    padding: 0%;
    width:15px;
}




input{
    border:none;
    margin-left: 10px;
}

.submit:hover{
    color: white;
    background-color: rgb(82, 80, 80);
    border-style: solid;
    border-color:rgb(82, 80, 80);
}



.list:hover{
    background-color: unset;
}

.reset_container{
    width:100%;
    display: flex;
    padding: 0 20px 20px 20px;  
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
}
.formContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.container{
    display: flex;
    gap: 15px;
    border: 2px solid  #2E7D32;
    border-radius: 10px;
    padding: 20px;
   /* margin-block: 50px; */
}
.text{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 10px;
    font-size: 1rem;
}

.forgetPasswordFields{
    display: flex;
    flex-direction: column;
    gap: 15px;
    
}
.back_to_login{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}



.error{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: red;
    align-self: center;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .left {
        display: none; /* Hide the carousel */
    }
    
    .right {
        width: 100%; /* Make the login section full width */
    }
    
    .major {
        width: 90%; /* Adjust container width for better mobile experience */
    }
}